<section class="fnh_login">
  <div class="fnh-shape">
    <img src="../../assets/shape1.png" alt="Shape">
  </div>
  <div class="container">
    <div class="row">
      <div class="col-lg-8">
        <div class="justify-content-between fnh-column-wrap">
          <div class="fnh-animated-img">
            <img src="../../assets/bg34-1.png" alt="Animated Image">
          </div>
          <div class="fnh-logo">
            <img src="../../assets/logo.png" class="logo-view ">
          </div>
          <div class="fxt-middle-content">
            <h1 class="fxt-main-title">Intra-Management portal</h1>
            <div class="fxt-switcher-description1">If you don’t have an account You can<a href="register-34.html"
                                                                                          class="fxt-switcher-text ms-2">Sign
              Up</a></div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="justify-content-center fnh-column-wrap">
          <div class="fnh-form">

            <div *ngIf="visible1">
                <h1 class="mb-5">Welcome to Finch</h1>
                <h4>Login As</h4>
              <form [formGroup]="loginType" (submit)="getLoginType()" ngNativeValidate>
                <mat-radio-group formControlName="type" aria-label="Select an option"
                                 class="d-flex justify-content-center mt-3">
                  <mat-radio-button class="mx-5 " value="admin"> Admin</mat-radio-button>
                  <mat-radio-button class="mx-5" value="employee"> Employee</mat-radio-button>
                </mat-radio-group>
                <div class="ms-2 mt-5 d-flex align-bottom">
                  <button type="submit" class="btn button-view w-100">Continue</button>
                </div>
              </form>
            </div>

            <div class="form-view" *ngIf="visible2">
              <h1 class="mb-5">Welcome to Finch</h1>
              <h4>Login As </h4>
              <form [formGroup]="formMobile" (submit)="getLoginOtp()" ngNativeValidate class="mt-4">
                <div class="form-floating mb-5">
                  <input type="text" appAutoFocus formControlName="mobile" [autofocus]="visible2"
                         class="form-control ms-2" id="floatingInput" placeholder="8288...." required>
                  <label for="floatingInput">Mobile</label>
                </div>
                <app-loader-button [buttonText]="'Send Otp'" [customButtonClass]="'btn button-view w-100'"
                                   [isLoading]="sendingOtp" [wrapperClass]="'w-100'"
                                   [loaderColor]="'#ffffff'"></app-loader-button>
              </form>
            </div>

            <div *ngIf="visible3" class="mobile">
              <form [formGroup]="formOtp" (submit)="login()">
<!--                <div class="form-view">-->
<!--                </div>-->
                <div class="form-floating mb-3 d-flex align-items-center">
                  <input type="text" class="form-control ms-2" id="floatingInput1" disabled="true" value="{{mobile}}">
                  <label for="floatingInput">Mobile</label>
                  <i class="bi bi-pencil" (click)="reEnteredNumber()"></i>
                </div>

<!--                <div class="form-floating mb-3">-->
<!--                  <input appAutoFocus type="text" formControlName="otp" class="form-control" maxlength="6"-->
<!--                         minlength="6"-->
<!--                         pattern="[0-9]*" id="floatingInput2" placeholder="OTP" required>-->
<!--                  <label for="floatingInput1" class="ms-2 login-labels">OTP</label>-->
<!--                </div>-->

                <div class="mb-2 ms-4" *ngIf="visible3">
                  <ng-otp-input [formCtrl]="otp" [config]="{length: 6}" ></ng-otp-input>
                </div>

                <div *ngIf="loginError" class="alert-danger bg-transparent">
                  <p>{{ loginError }}</p>
                </div>
                <app-loader-button [disabled]="formOtp.invalid"  [buttonText]="'Continue'" [customButtonClass]="'btn button-view w-100'"
                                   [isLoading]="validatingOtp" [wrapperClass]="'w-100'" [loaderColor]="'#ffffff'">
                </app-loader-button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--<section class="login_page">-->
<!--  <div class="container-fluid ">-->
<!--    <div class="row form-group">-->
<!--      <div class="col-md-5  text-white  d-flex flex-column justify-content-center" id="col1">-->
<!--        <div>-->
<!--          <div class="d-flex justify-content-center mb-4">-->
<!--            <img src="assets/office-desk-animation.gif" style="width: 600px; height: 500px">-->
<!--          </div>-->
<!--          <div class=" d-flex justify-content-center">-->
<!--            <label id="labelHi">Welcome to Finch</label>-->
<!--          </div>-->
<!--          <div class=" d-flex justify-content-center">-->
<!--            <p id="pWelcome">Intra-Management portal</p>-->
<!--          </div>-->

<!--        </div>-->
<!--      </div>-->

<!--      <div class="col-md-4  m-auto" id="col2">-->
<!--        <div class="login_form-wrapper">-->
<!--          <div class=" card-view  p-5 bg-white">-->
<!--            <div class="row">-->
<!--              <div class="row">-->
<!--                <div class=" h-50 d-flex justify-content-center m-2">-->
<!--                  <img src="../../assets/logo.png" class="logo-view ">-->
<!--                </div>-->
<!--                <div class="m-2 d-flex justify-content-center">-->
<!--                  <p class="textLogin-view text-center"> Please login here. Enter your Mobile number and get the OTP and-->
<!--                    continue</p>-->
<!--                </div>-->
<!--              </div>-->

<!--              <div *ngIf="visible1">-->
<!--                <div class="m-2 mt-5 d-flex justify-content-center">-->
<!--                  <h4>Login As</h4>-->
<!--                </div>-->
<!--                <form [formGroup]="loginType" (submit)="getLoginType()" ngNativeValidate>-->
<!--                  <mat-radio-group formControlName="type" aria-label="Select an option"-->
<!--                                   class="d-flex justify-content-center mt-5" required>-->
<!--                    <mat-radio-button class="mx-5 " value="admin"> Admin</mat-radio-button>-->
<!--                    <mat-radio-button class="mx-5" value="employee"> Employee</mat-radio-button>-->
<!--                  </mat-radio-group>-->
<!--                  <div class="ms-2 mt-5 d-flex align-bottom">-->
<!--                    <button type="submit" class="btn button-view w-100">Continue</button>-->
<!--                  </div>-->
<!--                </form>-->
<!--              </div>-->

<!--              <div class="form-view" *ngIf="visible2">-->
<!--                <form [formGroup]="formMobile" (submit)="getLoginOtp()" ngNativeValidate>-->
<!--                  <div class="form-floating mb-5">-->
<!--                    <input type="text" appAutoFocus formControlName="mobile" [autofocus]="visible2"-->
<!--                           class="form-control ms-2" id="floatingInput" placeholder="8288...." required>-->
<!--                    <label for="floatingInput">Mobile Number</label>-->
<!--                  </div>-->
<!--                  <app-loader-button [buttonText]="'Send Otp'" [customButtonClass]="'button-view w-100'"-->
<!--                                     [isLoading]="sendingOtp" [wrapperClass]="'w-100'"-->
<!--                                     [loaderColor]="'#ffffff'"></app-loader-button>-->
<!--                </form>-->
<!--              </div>-->

<!--              <div *ngIf="visible3" class="mobile">-->
<!--                <form [formGroup]="formOtp" (submit)="login()" ngNativeValidate>-->
<!--                  <div class="form-view">-->
<!--                  </div>-->
<!--                  <div class="form-floating mb-3 d-flex align-items-center">-->
<!--                    <input type="text" class="form-control ms-2" id="floatingInput1" disabled="true" value="{{mobile}}">-->
<!--                    <label for="floatingInput">Mobile Number</label>-->
<!--                    <i class="bi bi-pencil cusor-p" (click)="reEnteredNumber()"></i>-->
<!--                  </div>-->

<!--                  <div class="form-floating mb-3">-->
<!--                    <input appAutoFocus type="text" formControlName="otp" class="form-control" maxlength="6"-->
<!--                           minlength="6"-->
<!--                           pattern="[0-9]*" id="floatingInput2" placeholder="OTP" required>-->
<!--                    <label for="floatingInput1" class="ms-2 login-labels">OTP</label>-->
<!--                  </div>-->
<!--                  <div *ngIf="loginError" class="alert-danger bg-transparent">-->
<!--                    <p>{{ loginError }}</p>-->
<!--                  </div>-->
<!--                  <app-loader-button [buttonText]="'Continue'" [customButtonClass]="'button-view w-100'"-->
<!--                                     [isLoading]="validatingOtp" [wrapperClass]="'w-100'"-->
<!--                                     [loaderColor]="'#ffffff'"></app-loader-button>-->
<!--                </form>-->
<!--              </div>-->

<!--            </div>-->


<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--    </div>-->

<!--  </div>-->

<!--</section>-->












